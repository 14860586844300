<template>
  <div class="h-100 border flex-fill position-relative d-flex">

    <img src="@/static/dog-left-right.png" class="position-absolute w-100" style="max-width:200px" />
    <img src="@/static/dog-right-left.png" class="position-absolute w-100" style="max-width:200px;right:0;bottom: 0;" />
    <div class="m-auto text-center">
      <img src="@/static/logo.png" style="max-width:250px" />
      <h1 class="text-white h5" style="font-weight: normal;margin-top:-1em">All Your Pet Needs In Your Hand</h1>
    </div>

  </div>
</template>

<script>

import store from '@/store'

export default {
  components: {
  },
  methods: {
    toggleLoading() {
    },
  },
  mounted() {
    if(this.$isMobile())
      setTimeout(() => {
        this.$router.push('landing')
      }, 2000);
    else
      this.$router.push('homepage/desktop')
  },
  computed: {
    isLoading() {
      return store.getters.isLoading
    },
  },
  watch: {
  },
  data() {
    return {
    }
  }
};
</script> 